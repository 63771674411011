import type { HTMLAttributes } from 'react';
import Head from 'next/head';
import { PreloadImage } from '$util/PreloadImage';
import { Img } from '@/components/atoms/Img';

import { cloudinaryLoader, getCloudinarySrc } from '@/components/atoms/Img/cloudinary';
import styles from './index.module.scss';

export function DoubleLinesImg() {
  return (
    <>
      <PreloadImage
        provider="cloudinary"
        src="/hero-landing/double_line_hero_rq4wl1.png"
        displayFrom="large"
        width={800}
      />
      <Img
        className={styles.hero__lines}
        provider="cloudinary"
        src="/hero-landing/double_line_hero_rq4wl1.png"
        alt="Double line"
        width={800}
        height={800}
        loading="eager"
        priority
      />
    </>
  );
}

const heroImageCloudinaryLoader = cloudinaryLoader();

const carImgSrcs = {
  mobile: heroImageCloudinaryLoader({
    src: getCloudinarySrc('/brand/jmjlgav9wmhwizmrf543.webp'),
    width: 324,
  }),
  mobile2x: heroImageCloudinaryLoader({
    src: getCloudinarySrc('/brand/jmjlgav9wmhwizmrf543.webp'),
    width: 648,
  }),
  desktop: heroImageCloudinaryLoader({
    src: getCloudinarySrc('/brand/ekedjidgox7zpzivoirt.webp'),
    width: 516,
  }),
  desktop2x: heroImageCloudinaryLoader({
    src: getCloudinarySrc('/brand/ekedjidgox7zpzivoirt.webp'),
    width: 1032,
  }),
};

export function CarImage({ className }: HTMLAttributes<HTMLPictureElement>) {
  return (
    <picture className={className}>
      <Head>
        <link
          rel="preload"
          as="image"
          imageSrcSet={`${carImgSrcs.mobile2x} 2x, ${carImgSrcs.mobile}`}
          sizes={`(max-width: ${parseInt(styles.largeBreakpoint, 10) - 1}px) 324w, 324w`}
          media={`(max-width: ${parseInt(styles.largeBreakpoint, 10) - 1}px)`}
        />
        <link
          rel="preload"
          as="image"
          imageSrcSet={`${carImgSrcs.desktop2x} 2x, ${carImgSrcs.desktop}`}
          sizes={`${carImgSrcs.desktop2x} 2x, ${carImgSrcs.desktop} 516w, 516w`}
          media={`(min-width: ${parseInt(styles.largeBreakpoint, 10)}px)`}
        />
      </Head>
      <source
        srcSet={`${carImgSrcs.mobile2x} 2x, ${carImgSrcs.mobile}`}
        media={`(max-width: ${parseInt(styles.largeBreakpoint, 10) - 1}px)`}
        width={324}
        height={324}
      />
      <source
        srcSet={`${carImgSrcs.desktop2x} 2x, ${carImgSrcs.desktop}`}
        media={`(min-width: ${parseInt(styles.largeBreakpoint, 10)}px)`}
        width={516}
        height={516}
      />
      <img
        className={styles.hero__car_asset}
        src={carImgSrcs.mobile}
        alt=""
        width={324}
        height={324}
        loading="eager"
      />
    </picture>
  );
}
