/* eslint-disable indent */
import config from 'config';
import type { ImageLoader } from 'next/legacy/image';

export const CLOUDINARY_PATH = config.get<string>('public.imageProvider.cloudinary.path');
export const CLOUDINARY_LANDING_DIR = config.get<string>('public.imageProvider.cloudinary.dir');

export interface CloudinaryProps {
  /**
   * By default the format is `auto` which will serve the best format for the browser that requests the image.
   * For `svg` extensions the format will default to `svg` if not specified otherwise and the quality will be set to 100.
   */
  readonly format?: 'auto' | 'svg' | 'webp' | 'jpg' | 'png';
}

/**
 * Builds a image variation link.
 * From next/image code.
 * @see https://github.com/vercel/next.js/blob/78b17351fb9265dc80329d3e331022f6ebc7d0ff/packages/next/client/image.tsx#L579
 */
export const cloudinaryLoader: (cloudinaryProps?: CloudinaryProps) => ImageLoader =
  ({ format: f } = {}) =>
  ({ src, width, quality: q }) => {
    let format = f;
    let quality = q || 'auto';
    if (!format) {
      format = 'auto';
      const ext = src.split('.').pop();
      if (ext === 'svg') {
        format = 'svg';
        quality = 100;
      }
    }
    const relSrc = src.replace(CLOUDINARY_PATH, '');
    const params = [`f_${format}`, 'c_limit', `w_${width}`, `q_${quality}`];
    const paramsString = `${params.join(',')}`;
    return `${CLOUDINARY_PATH}/${paramsString}${relSrc}`;
  };

export const checkCloudinarySrc = (src: string) => {
  const isAbsolutePath = src.startsWith('http');
  if (!isAbsolutePath) {
    if (!src.startsWith('/')) {
      // consistent with default behaviour
      throw new Error(`Relative image paths have to start with '/'. Src provided - ${src}`);
    }
  } else if (!src.startsWith(CLOUDINARY_PATH)) {
    throw new Error(
      `Absolute image paths with provider cloudinary have to start with cloudinary URL. Src provided - ${src}`
    );
  }
};

export const getCloudinarySrc = (src: string) => {
  checkCloudinarySrc(src);
  const isAbsolutePath = src.startsWith('http');
  if (isAbsolutePath) return src;
  return `/${CLOUDINARY_LANDING_DIR}${src}`;
};
