/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import classNames from 'classnames';
import { formatAmountWithCurrency, type Segment } from '@fixter/i18n';
import { VrmLookupModal } from '@/components/organisms/VrmLookupModal';
import type { VrmLookupTranslations } from '@/components/molecules/VrmLookup';
import styles from './index.module.scss';
import { renderColumnValue } from './utils';

export interface VrmLookupModalTranslations {
  modalTitle: ReactNode;
  modalCta: string;
  modalToggleText: string;
}

export interface ComparisonTableTranslations {
  readonly ourOffer: string;
  readonly mostPopular: string;
  readonly startingFrom: string;
  readonly getQuote: string;
  readonly modalTitle: ReactNode;
  readonly modalCta: string;
  readonly modalToggleText: string;
  readonly vrmLookupTranslations: VrmLookupTranslations;
}

export interface ComparisonTableRow {
  readonly lineItemName: string;
  readonly description?: string; // @TODO add tooltip
  readonly servicesValues: ReactNode[];
}
export interface ComparisonTableService {
  readonly name: string;
  readonly price: number;
  readonly sku?: string;
  readonly popular?: boolean;
}

export interface ComparisonTableProps extends ComponentPropsWithoutRef<'div'> {
  readonly translations: ComparisonTableTranslations;
  readonly services: ComparisonTableService[];
  readonly lineItems: ComparisonTableRow[];
  readonly segment: Segment;
  readonly forceMobile?: boolean;
}

export function ComparisonTable({
  className,
  translations,
  services,
  lineItems,
  segment,
  forceMobile,
  children,
  ...props
}: ComparisonTableProps): JSX.Element {
  const { ourOffer, mostPopular, startingFrom, getQuote, modalTitle, modalCta, vrmLookupTranslations } =
    translations;

  return (
    <table
      className={classNames(
        ComparisonTable.displayName,
        styles.component,
        className,
        forceMobile && styles.forceMobile
      )}
      {...props}
    >
      <thead className={styles.tableHead}>
        <tr className={styles.tableHeadRow}>
          <th>{ourOffer}</th>
          {services.map(({ name, popular }) => (
            <th key={name} className={popular ? styles.PurpleText : ''}>
              {popular && (
                <div>
                  <span>{mostPopular}</span>
                </div>
              )}
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {lineItems.map(({ lineItemName, servicesValues }) => (
          <tr key={lineItemName} className={styles.tableRow}>
            <td>{lineItemName}</td>
            {servicesValues.map((value, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <td key={`${lineItemName}Col${index}`}>{renderColumnValue(value)}</td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot className={styles.tableFooter}>
        <tr className={styles.tableFooterRow}>
          <td />
          {services.map(({ price }) => (
            <td key={price}>
              <span>{startingFrom}</span>
              <span className={styles.productPrice}>{formatAmountWithCurrency(price, segment, 0)}</span>
            </td>
          ))}
        </tr>
        <tr className={styles.tableFooterButtons}>
          <td />
          {services.map(({ price, sku }) => (
            <td key={price} className={styles.button}>
              <VrmLookupModal
                id="ComparisonTableModal"
                toggleButton={{
                  children: getQuote,
                  size: 'M',
                  className: styles.getQuoteButton,
                }}
                heading={modalTitle}
                ctaText={modalCta}
                formProps={{ translations: vrmLookupTranslations, sku }}
              />
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
}
ComparisonTable.displayName = 'ComparisonTable';
