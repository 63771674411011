'use client';

import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Locale } from '@fixter/i18n';
import { useResizeViewport } from '$util/hooks/useResizeViewport';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';
import { HeroSection } from '@/components/molecules/HeroSection';
import { VrmLookupDumbModal } from '@/components/molecules/VrmLookupDumbModal';
import { VrmLookupDumb } from '@/components/molecules/VrmLookupDumb';
import { translations as vrmLookupModalTranslations } from '@/components/molecules/VrmLookupDumbModal/translations';
import { translations as vrmLookupFormTranslations } from '@/components/molecules/VrmLookupDumb/translations';
import type { CmsHeroSectionClientProps } from './schema';

import styles from './index.module.scss';

export default function CmsHeroSectionClient(props: CmsHeroSectionClientProps): ReactNode {
  const {
    locale,
    heading,
    mainCta,
    grandLabel,
    grandLabelCndPrice,
    subheading,
    partOfRenaultText,
    partOfRenaultLogo,
    sku,
    campaignId,
  } = props;
  const [showStickyButton, setShowStickyButton] = useState(false);

  const [stickyButtonIntersectionObserver, setStickyButtonIntersectionObserver] = useState<
    IntersectionObserver | undefined
  >(undefined);

  /**
   * @NOTE implementation currently fires the intersection observer on intersection
   * for all viewports because
   *
   * 1. we have not yet moved the useResizeViewport hook
   * to the new grid breakpoints
   * 2. This needs to be run in the useEffect hook so not sure of the best way to
   * do it in both hooks at the moment
   *
   * This means the IntersectionObserver callback will be run for every breakpoint when
   * the intersection ratio threshold is crossed - this is still much better performance
   * than using a scroll listener and with greatly reduce jank already.
   *
   * @TODO extract the Intersection Observers callback function outside the component scope
   *
   * @TODO when the useResizeViewport component is using the new breakpoints,
   * then refactor so that the observer is initialised independtly, that the target is observed
   * when the viewport is matched and unobserved when it does not.
   */
  useEffect(() => {
    const headingElement = document.querySelector<HTMLElement>('.CarHeading');
    if (campaignId || !headingElement) return undefined;
    const contentSectionEl = document.querySelector<HTMLElement>(`.HeroSection`);
    const topNavBarEl = document.querySelector<HTMLElement>('.TopNavigationBar');

    if (!contentSectionEl || !topNavBarEl) return undefined;

    const isTop = topNavBarEl.offsetTop === contentSectionEl.offsetTop;
    if (!isTop) {
      contentSectionEl.firstElementChild?.classList.remove('top');
      return undefined;
    }

    topNavBarEl.classList.add('transparent');
    topNavBarEl.classList.add('transition');

    const observerTarget = headingElement;
    const toggleNavBg = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (window.innerWidth >= 600) {
          if (entry.intersectionRatio < 1) {
            // make nav opaque
            topNavBarEl.classList.remove('transparent');
          } else {
            // make nav transparent
            topNavBarEl.classList.add('transparent');
          }
        }
      });
    };
    const observer = new IntersectionObserver(toggleNavBg, {
      rootMargin: '-80px 0px 0px',
      threshold: [1],
    });

    observer.observe(observerTarget);

    return () => {
      observer.unobserve(observerTarget);
    };
  }, [campaignId]);

  useEffect(() => {
    const heroVrmElement = document.querySelector<HTMLElement>('.CarVrmForm');
    if (heroVrmElement) {
      const target = heroVrmElement;
      const observer = new IntersectionObserver(
        ([observedTarget]) => {
          const { isIntersecting } = observedTarget;
          if (window.scrollY === 0) {
            setShowStickyButton(false);
          } else {
            setShowStickyButton(!isIntersecting);
          }
        },
        {
          threshold: [0],
        }
      );

      observer.observe(target);

      setStickyButtonIntersectionObserver(observer);

      return () => {
        observer.unobserve(target);
      };
    }
    return () => undefined; // needed due to consistent return lint rule
  }, [setStickyButtonIntersectionObserver, setShowStickyButton]);

  useResizeViewport({
    breakpoint: 'small',
    direction: 'up',
    fn: (isDesktop) => {
      const heroVrmElement = document.querySelector<HTMLElement>('.CarVrmForm');
      // @NOTE if desktop, don't show
      if (heroVrmElement) {
        if (isDesktop) {
          stickyButtonIntersectionObserver?.unobserve(heroVrmElement);
          setShowStickyButton(false);
        } else {
          stickyButtonIntersectionObserver?.observe(heroVrmElement);
        }
      }
    },
  });

  const isABTestCndPriceFR =
    useActiveExperiment(ActiveExperiments.CndPriceFR, 'B') && locale === Locale.FR_FR;

  return (
    <>
      {/* @todo move back into SC when the ActiveExperiments.CndPriceFR is over */}
      <HeroSection
        className={classNames(styles.topAdjustment, 'top')}
        locale={locale}
        heading={heading}
        mainCta={mainCta}
        grandLabel={isABTestCndPriceFR ? grandLabelCndPrice : grandLabel}
        subheading={subheading}
        partOfRenaultText={partOfRenaultText}
        partOfRenaultLogo={partOfRenaultLogo}
        sku={sku}
        campaignId={campaignId}
      />
      {showStickyButton && (
        <div className={styles.stickyCTA}>
          <VrmLookupDumbModal
            size="L"
            triggeredFrom="CmsHeroSection"
            {...vrmLookupModalTranslations[locale].default}
            toggleButtonText={mainCta || vrmLookupModalTranslations[locale].default.toggleButtonText}
          >
            <VrmLookupDumb
              locale={locale}
              sku={sku}
              {...vrmLookupFormTranslations[locale].default}
              campaignId={campaignId}
            />
          </VrmLookupDumbModal>
        </div>
      )}
    </>
  );
}
