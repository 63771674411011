import { useMemo } from 'react';
import type { ImageConfigComplete } from 'next/dist/shared/lib/image-config';
import { imageConfigDefault } from 'next/dist/shared/lib/image-config'; // next@13.4.4: could break in future versions
import Head from 'next/head';
import type { ImageLoaderProps } from 'next/legacy/image';
import type { misc } from '$util/theme/vars';
import type { CloudinaryProps } from '$util/image/cloudinary';
import { cloudinaryLoader, getCloudinarySrc } from '$util/image/cloudinary';
import { getBreakpoint } from '$util/theme/breakpoints';

/**
 * @warn Copied types from NextJs internals
 * @see https://github.com/vercel/next.js/blob/f19b31ad93c313cb86d9a589c931f8f9718a7fb2/packages/next/src/client/legacy/image.tsx#L55
 */
type ImageConfig = ImageConfigComplete & { allSizes: number[] };
type ImageLoaderPropsWithConfig = ImageLoaderProps & {
  config: Readonly<ImageConfig>;
};
type ImageLoaderWithConfig = (resolverProps: ImageLoaderPropsWithConfig) => string;

const { deviceSizes, imageSizes } = imageConfigDefault;
const allSizes = [...deviceSizes, ...imageSizes].sort((a, b) => a - b);

/**
 * @warn Copied logic from NextJs internals
 * @see https://github.com/vercel/next.js/blob/f19b31ad93c313cb86d9a589c931f8f9718a7fb2/packages/next/src/client/legacy/image.tsx#L174-L176
 */
const defaultLoader: ImageLoaderWithConfig = ({ src, width, quality }) =>
  `_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`;

const getSrcSet = (
  { src, quality, width, format }: Pick<Props, 'src' | 'quality' | 'width' | 'format'>,
  provider: Props['provider']
): string => {
  if (!src) return '';
  let imgSrc = src;
  let loader = defaultLoader;
  if (provider === 'cloudinary') {
    imgSrc = getCloudinarySrc(src);
    loader = cloudinaryLoader({ format });
  }
  let sizes = allSizes;
  let kind = 'w';
  if (width) {
    /**
     * @warn Copied logic from NextJs internals
     * @see https://github.com/vercel/next.js/blob/f19b31ad93c313cb86d9a589c931f8f9718a7fb2/packages/next/src/client/legacy/image.tsx#L321-L335
     */
    sizes = [width, width * 2].map((w) => allSizes.find((p) => p >= w) || allSizes[allSizes.length - 1]);
    kind = 'x';
  }
  const loaderConfig: ImageLoaderPropsWithConfig['config'] = { ...imageConfigDefault, allSizes };
  return sizes
    .map(
      (w, i) =>
        `${loader({ src: imgSrc, width: w, quality, config: loaderConfig })} ${
          kind === 'w' ? w : i + 1
        }${kind}`
    )
    .join(', ');
};

const getMediaQuery = ({ displayFrom, displayTo }: Pick<Props, 'displayFrom' | 'displayTo'>): string =>
  [
    displayFrom && `(min-width: ${getBreakpoint(displayFrom)})`,
    displayTo && `(max-width: ${getBreakpoint(displayTo, -1)})`,
  ]
    .filter((d) => !!d)
    .join(' and ');

type MediaQueryBreakpoint = keyof (typeof misc)['mediaQueryBreakpoints'];

export interface Props extends CloudinaryProps {
  readonly provider?: 'cloudinary';
  /**
   * Generates `imageSrcSet` with multiple image sizes as next/image would.
   */
  readonly src?: string;
  /**
   * To use a fixed link as opposed to `imageSrcSet` with multiple image sizes.
   * eg. for when a css background-image needs to be preloaded.
   */
  readonly href?: string;
  readonly displayFrom?: MediaQueryBreakpoint;
  readonly displayTo?: MediaQueryBreakpoint;
  readonly quality?: number;
  /**
   * Specify the width of the image to calculate DPI size.
   * Used when `layout` is `fixed` or `intrinsic`.
   */
  readonly width?: number;
}

/**
 * Preloads image based on media breakpoints.
 * @important set `loading="eager"` on the corresponding `Img`.
 * @note  use `priority` prop on `Img` when preload doesn't have to be conditional on breakpoints.
 * @see https://nextjs.org/docs/app/api-reference/components/image#priority
 */
export function PreloadImage({
  provider,
  src,
  href,
  displayFrom,
  displayTo,
  quality,
  width,
  format,
}: Props): JSX.Element {
  const keySrc = src || href;

  const imageSrcSet = useMemo(
    () => getSrcSet({ src, quality, width, format }, provider),
    [src, quality, width, format, provider]
  );
  const media = useMemo(() => getMediaQuery({ displayFrom, displayTo }), [displayFrom, displayTo]);

  let LinkPreloadImage;
  if (href) {
    if (media) {
      LinkPreloadImage = <link rel="preload" as="image" href={href} media={media} />;
    } else {
      LinkPreloadImage = <link rel="preload" as="image" href={href} />;
    }
  } else if (media) {
    // @ts-ignore `imageSrcSet` not yet in type definitions but valid
    LinkPreloadImage = <link rel="preload" as="image" imageSrcSet={imageSrcSet} media={media} />;
  } else {
    /**
     * same behaviour as setting `priority` prop on `Image`
     */
    // @ts-ignore `imageSrcSet` not yet in type definitions but valid
    LinkPreloadImage = <link rel="preload" as="image" imageSrcSet={imageSrcSet} imageSizes="100vw" />;
  }

  return <Head key={keySrc}>{LinkPreloadImage}</Head>;
}
PreloadImage.displayName = 'PreloadImage';
