import React from 'react';
import classNames from 'classnames';
import { Locale } from '@fixter/i18n';
import { Col, Row } from '$src-components/atoms/Grid';
import { HeadingLevels } from '$src-components/atoms/HeadingLevels';
import { TrustPilot } from '@/components/atoms/TrustPilot';
import { translations as vrmLookupModalTranslations } from '@/components/molecules/VrmLookupDumbModal/translations';
import { translations as vrmLookupFormTranslations } from '@/components/molecules/VrmLookupDumb/translations';
import { GrandLabel } from '@/components/atoms/GrandLabel';
import { PartOfRenault } from '../PartOfRenault';
import type { HeroSectionProps } from './types';
import { CarImage, DoubleLinesImg } from './hero';
import { WithABLookupCTA } from './WithAbTestClient';

import styles from './index.module.scss';

export function HeroSection({
  locale,
  heading,
  subheading,
  mainCta,
  grandLabel,
  partOfRenaultText,
  partOfRenaultLogo,
  sku,
  campaignId,
  className,
  ...props
}: HeroSectionProps): JSX.Element {
  return (
    <Row
      className={classNames(
        'HeroSection',
        styles.row,
        styles.topAdjustment,
        styles.heroCNDAvailable,
        className
      )}
      {...props}
    >
      <Col extraSmall={4} small={4} medium={5} large={7} className={styles.col}>
        <div className={classNames(styles.headings, 'CarHeading')}>
          <HeadingLevels semanticLevel={1} styleLevel={1} className={styles.hero__title}>
            {heading}
          </HeadingLevels>
          {subheading && <h2 className={styles.hero__subheading}>{subheading}</h2>}
          {grandLabel && <GrandLabel className={styles.grandLabel}>{grandLabel}</GrandLabel>}
        </div>
      </Col>
      <Col
        extraSmall={4}
        small={2}
        smallStart={5}
        medium={5}
        mediumStart={8}
        large={4}
        largeStart={9}
        className={classNames(styles.col, styles.col__images)}
      >
        <DoubleLinesImg />
        <CarImage className={styles.hero__car_picture} />
      </Col>
      <Col extraSmall={4} small={3} medium={5} className={styles.colVrmLookup}>
        <div className={classNames(styles.col__ctaLookupModal, styles.ctaLookupModal, 'CarVrmForm')}>
          <WithABLookupCTA
            locale={locale}
            sku={sku}
            campaignId={campaignId}
            triggeredFrom={HeroSection.displayName}
            modalTranslations={vrmLookupModalTranslations[locale].default}
            formTranslations={vrmLookupFormTranslations[locale].default}
          >
            {mainCta || vrmLookupModalTranslations[locale].default.toggleButtonText}
          </WithABLookupCTA>
        </div>
      </Col>
      <Col extraSmall={4} small={3} medium={6} className={styles.hero__review}>
        <TrustPilot
          locale={locale}
          width="100%"
          theme="dark"
          className={classNames(styles.trustpilot, locale === Locale.FR_FR && styles.trustpilotFR)}
        />
        {typeof partOfRenaultText === 'string' && partOfRenaultLogo && (
          <PartOfRenault
            className={styles.renault}
            layout="horizontal"
            preload
            label={partOfRenaultText}
            logo={partOfRenaultLogo}
          />
        )}
      </Col>
      <Col
        extraSmall={2}
        extraSmallStart={2}
        small={2}
        smallStart={5}
        medium={4}
        mediumStart={8}
        className={styles.hero__renault}
      >
        {grandLabel ? (
          <GrandLabel className={styles.grandLabel}>{grandLabel}</GrandLabel>
        ) : (
          <PartOfRenault
            className={styles.partOfRenault}
            layout="horizontal"
            preload
            label={partOfRenaultText}
            logo={partOfRenaultLogo}
          />
        )}
      </Col>
    </Row>
  );
}

HeroSection.displayName = 'HeroSection';
